import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

// html {
//   main{
//     opacity: 0;
//     transition: 250ms opacity ease;
//   }
//   // .artist-list {
//   //   opacity: 0;
//   //   transition: 250ms opacity ease;    
//   // }
// }

// html.wf-active,
// html.wf-inactive {
//   main{
//     opacity: 1;
//   }
//   // .artist-list {
//   //   opacity: 1;    
//   // }
// }

body {
  font-family: "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  word-break: break-word;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.3px;
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

/* video,
img {
  width: 100%;
  margin: 0;

  display: block;
} */

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }


// - - - - - - - - - - - TF STYLES
[aria-current] > li {
    opacity:1;
  }

body {
  transition: 1s ease;
  background-color: #fc603e;
}

.slick-dots {
  width: auto !important;
}
.slick-dots ul li {
  font-weight: bold;
  opacity: 0.6; 
  color: white;
  margin: 0;
  margin-right: -4px;
  font-size: 18px;
}
@media (min-width: 1200px) {
  .slick-dots ul li {
    font-size: 24px;
  }    
}

.slick-dots ul li.slick-active {
  opacity: 1;
}
.slick-list {
  height: 100%
}
.slick-track {
  height: 98vh;
  /* display: flex !important;
  align-items: center;
  justify-content: center;   */
  display: flex !important;
  align-items: center;
}
.slick-slide {
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.project-carousel-img.portrait {
  height: calc(100vh - 20px);
}
.project-carousel-img.landscape {
  height: 40vw;
}
.project-carousel-img.landscape_wide {
  height: auto;
}
.project-carousel-img.square {
  height: calc(100vh - 20px);
  }  

@media (max-width: 640px) {
    .project-carousel-img.portrait {
    width: 100%;
    height: auto;
  }
  .project-carousel-img.landscape {
    width: 100%;
    height: auto;
  } 
  .project-carousel-img.square {
    width: 100%;
    height: auto;
  } 
  .slick-track {
      display: flex !important;
      /* background-color: blue; */
      height: inherit !important;
  }

  .slick-slider {
      height: inherit !important;
      /* height: 200px; */
      /* background-color: green; */
  }     
}
@media (max-width: 900px) and (orientation: landscape) {
  .project-carousel-img.portrait {
    width: auto !important;
    height: calc(100vh - 20px);
  }
  .project-carousel-img.landscape {
    width: auto !important;
    height: calc(100vh - 20px);
  } 
  .project-carousel-img.square {
    width: auto !important;
    height: calc(100vh - 20px);
  } 
}

@media (min-width: 768px) and (orientation: portrait) {
  .slick-slider {
    height: 100%;
  }
  .project-carousel-img.portrait {
    height: 60vh;
  }
  .project-carousel-img.landscape {
    height: 40vw;
  }
  .project-carousel-img.square {
    height: 40vw;
  }  
  .slick-dots ul li {
    font-size: 24px;
  }  
}

@media (hover: none) {
  body.index-page-body {
    animation: colorchange 20s linear 1s infinite;
    animation-direction: alternate;
  }
  @keyframes colorchange {
    0% {
      background: #fc603e;
    }
    10% {
      background: #3a8fb2;
    }
    20% {
      background: #012970;
    }
    30% {
      background: #67eedb;
    }
    40% {
      background: #5ca1a9;
    }
    50% {
      background: #5bbe95;
    }
    60% {
      background: #e42824;
    }
    70% {
      background: #84c6f7;
    }
    80% {
      background: #f381a8;
    }
    90% {
      background: #bbecf7;
    }
  }
}

.about-text p {
  font-size: 18px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  /* margin: 0;
  padding: 0; */
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: -0.5px;
}
@media (max-width: 500px) {
  .about-text p {
    font-size: 24px;
  } 
}  

.about-text p:first-child {
  margin-top:0;
  padding-top:0;
}
.about-text a {
  color: blue;
}
@media (min-width: 1200px) {
  .about-text p {
    font-size: 24px;
  }   
} 

body.index-page-body {
  overflow: hidden;
}
`;

export default GlobalStyle;
