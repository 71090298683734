module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Stencil","Helvetica Neue LT Pro"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Website Name","short_name":"Website Name","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cd8895c4e8fa55c2b1b0644c937e147"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"poppy-nash","accessToken":"MC5ZWjVzZHhVQUFDUUFzVmlV.U--_vTbvv706C--_vRDvv711NO-_vQp977-977-9Xu-_ve-_vVzvv73vv73vv73vv73vv73vv73vv73vv70477-9HzM","schemas":{"project":{"Main":{"uid":{"type":"UID","config":{"label":"slug"}},"project_title":{"type":"StructuredText","config":{"single":"heading1","label":"Project Title"}},"project_background_color":{"type":"Color","config":{"label":"Project Background Color"}},"project_meta":{"type":"StructuredText","config":{"multi":"paragraph,hyperlink","label":"Project Meta"}},"project_index_page_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Project Index Page Image"}},"index_image_position_left":{"type":"Number","config":{"label":"Index Image Position Left","placeholder":"Enter 0-80"}},"index_image_position_top":{"type":"Number","config":{"label":"Index Image Position Top","placeholder":"Enter 0-80"}},"project_carousel":{"type":"Group","config":{"fields":{"project_carousel_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Project Carousel Image"}}},"label":"Project Carousel"}}}},"about":{"Main":{"uid":{"type":"UID","config":{"label":"slug"}},"about_text":{"type":"StructuredText","config":{"multi":"paragraph, hyperlink","label":"about text"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/global/layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
